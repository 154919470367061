import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/BusinessSolar.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "Increased Business Value ",
    paragraph: "Add value to your company with solar panels. ",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Reliability ",
    paragraph: "Stress-free solar energy without power failures or glitches.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "Long-Term Cost Savings",
    paragraph: "Increase savings with reduced electricity costs.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "Setup and Upkeep",
    paragraph: "Professional installation and continuing maintenance.  ",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Sustainability ",
    paragraph: "Solar panel electricity produces no harmful emissions.",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Green Credentials",
    paragraph: "Turn your business green and gain more customers.",
  },
];

export const HeaderData = {
  src: Internet,
  title: "Business Solar",
  subtitle: "Save on Fees, Save the Planet",
  paragraph: "Compare the best business solar deals from top providers",
  to: Product_Quote_Routes.BusinessSolar,
};

export const DescriptionContent = {
  title: "Why Does Your Business Need Solar Energy?",
  paragraph:
    "Going solar can save you electricity and operating costs. It may make your company eligible for rebates from the government. Going solar can also contribute to converting leads and building brand loyalty, as an increasing amount of the population supports sustainable practices. ",
  to: Product_Quote_Routes.BusinessSolar,
  profitPoint1: "Reduced electricity costs",
  profitPoint2: " Reduced operating costs",
  profitPoint3: "Fewer harmful emissions ",
  profitPoint4: "Government rebate eligibility",
};

export const FAQS = [
  {
    title: "How do solar panels work?",
    answer: "Solar panels generate electricity using light from the sun.",
  },
  {
    title: "Is solar energy renewable?",
    answer: "Yes! Solar energy is inexhaustible.",
  },
  {
    title: "How do solar panels save my business money?",
    answer:
      "Solar panels can help your business to save money by reducing operation and electricity costs. They can also add value to your company and make you eligible for government rebates. ",
  },
  {
    title: "How much do solar panels cost?",
    answer:
      "Solar panels range in price, depending on the size and type. Our trusted supply partners can give you an obligation-free quote.   ",
  },
  {
    title: "What are solar batteries?",
    answer:
      "Solar batteries store the energy that your solar panels produce and allow you to use it later.",
  },
  {
    title: "Are solar panels a necessity? ",
    answer:
      "While solar panels aren’t a necessity, they can help your business to thrive in several ways. Along with reducing costs, they can also attract more customers. ",
  },
  {
    title: "How can Digital Comparison get me solar business solutions?",
    answer:
      "We will match your details with our trusted solar energy providers to find you the perfect solar partner. Once we get back to you with several quotes, simply compare the offers and choose the right supplier for you. ",
  },
  {
    title: "Which business solar supplier is the best?",
    answer:
      "The best solar supplier for you depends on your needs and budget. Every supplier that we work with is qualified, experienced, and an industry leader.   ",
  },
];
